import React, { PropsWithChildren } from "react";
import { Transition } from "@headlessui/react";
import { classNames, durationFromMs } from "../../../util/strings";
import {
  DEFAULT_TRANSITION_ENTER_DURATION,
  DEFAULT_TRANSITION_LEAVE_DURATION,
} from "../../../util/contants";

type FadeInOutTransitionProps = {
  show: boolean;
  enterMs?: number;
  leaveMs?: number;
  appear?: boolean;
};

const FadeInOutTransitionComponent = (
  props: PropsWithChildren<FadeInOutTransitionProps>,
) => {
  const { show, appear, enterMs, leaveMs, children } = props;

  return (
    <Transition
      show={show}
      enter={classNames("transition-opacity", durationFromMs(enterMs!))}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave={classNames("transition-opacity", durationFromMs(leaveMs!))}
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      appear={appear}
    >
      {children}
    </Transition>
  );
};

FadeInOutTransitionComponent.defaultProps = {
  enterMs: DEFAULT_TRANSITION_ENTER_DURATION,
  leaveMs: DEFAULT_TRANSITION_LEAVE_DURATION,
  appear: true,
};

export default FadeInOutTransitionComponent;
