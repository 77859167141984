import { components, operations } from "../spec";
import { fetchAndUnpackError, useWrappersFetcher, Wrapper } from "../connector";

export const createQuiz = async (
  request: components["schemas"]["CreateQuizRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["CreateQuizResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/create/")
      .method("post")
      .create()(request),
  );

export const listQuizQuestions = async (
  request: operations["reflection_api_quizzes_list_questions_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ListQuizQuestionsResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/list-questions/")
      .method("get")
      .create()(request),
  );

export const setQuizRecipients = async (
  request: components["schemas"]["SetQuizRecipientsRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ReflectionQuizDetailSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/set-recipients/")
      .method("post")
      .create()(request),
  );

export const approveQuiz = async (
  request: components["schemas"]["ApproveQuizRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    (
      | components["schemas"]["ReflectionQuizRelatedDetailSerializerWrapper"]
      | null
    ),
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/approve/")
      .method("post")
      .create()(request),
  );

export const listQuizzes = async (
  request: operations["reflection_api_quizzes_list_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ListQuizzesResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/reflection/api/quizzes/list/")
      .method("get")
      .create()(request ?? {}),
  );
