import React, { ReactElement } from "react";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import PSpinner from "../PSpinner";
import PBackButton from "../buttons/PBackButton";
import PCenterColumnStickyButtonScreen from "./PCenterColumnStickyButtonScreen";
import PErrors from "../input/PErrors";

type LoadingScreenProps = {
  errors?: string[];
  message?: string;
  onBackClicked?: (() => void) | null;
};

const LoadingScreenComponent = (props: LoadingScreenProps) => {
  const { errors, message, onBackClicked } = props;

  const getButtons = (): ReactElement[] => {
    if (!errors || errors.length === 0) {
      return [];
    }
    if (!onBackClicked) {
      return [];
    }
    return [
      <PBackButton onClick={onBackClicked} kind="primary" className="w-full" />,
    ];
  };

  return (
    <PCenterColumnStickyButtonScreen buttons={getButtons()}>
      <div className="flex flex-col items-center justify-center gap-3 text-center">
        {!errors || errors.length === 0 ? (
          <>
            <div className="h-10 w-10">
              <PSpinner />
            </div>
            <div>{message}</div>
          </>
        ) : (
          <>
            <ExclamationCircleIcon className="h-10 w-10 text-danger" />
            <PErrors errors={errors} />
          </>
        )}
      </div>
    </PCenterColumnStickyButtonScreen>
  );
};

LoadingScreenComponent.defaultProps = {
  errors: [],
  message: "loading...\n\ngive us just a moment please",
  onBackClicked: null,
};

export default LoadingScreenComponent;
