import { XCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import PIconButton from "./PIconButton";
import { CommonWellButtonProps } from "./common";

const PWellCancelButtonComponent = (props: CommonWellButtonProps) => (
  <PIconButton {...props} icon={<XCircleIcon />} />
);

PWellCancelButtonComponent.defaultProps = {
  disabled: false,
};

export default PWellCancelButtonComponent;
