import React, { ReactNode } from "react";
import { ChevronDoubleRightIcon } from "@heroicons/react/20/solid";
import PButton, { PButtonProps } from "./PButton";

type PSkipButtonProps = PButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const PSkipButtonComponent = (props: PSkipButtonProps) => {
  const { text, ...rest } = props;

  return <PButton {...rest}>{text}</PButton>;
};

PSkipButtonComponent.defaultProps = {
  text: "skip",
  icon: (
    <ChevronDoubleRightIcon className="h-6 w-5 flex-none" aria-hidden="true" />
  ),
};

export default PSkipButtonComponent;
