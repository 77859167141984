import { components, operations } from "../spec";
import { fetchAndUnpackError, useWrappersFetcher, Wrapper } from "../connector";

// eslint-disable-next-line import/prefer-default-export
export const reverseString = async (
  request: components["schemas"]["ReverseRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ReverseResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/api/examples/reverse")
      .method("post")
      .create()(request),
  );

export const generateRandomPhrase = async (
  request: components["schemas"]["GenerateRandomPhraseRequest"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ExampleRandomTaskDetailSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/api/examples/gen-phrase")
      .method("post")
      .create()(request),
  );

export const getExamplePhrase = async (
  request: operations["api_examples_get_phrase_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["ExampleRandomTaskDetailSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/api/examples/get-phrase")
      .method("get")
      .create()(request),
  );
