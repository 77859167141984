import React from "react";
import { classNames } from "../../../util/strings";

const exportClassNames: string[] = [
  "bg-primary",
  "bg-primary-lighter",
  "bg-primary-lightest",
  "bg-secondary",
  "bg-secondary-lighter",
  "bg-secondary-lightest",
  "bg-p-white",
  "bg-p-white-lighter",
  "bg-p-white-lightest",
  "bg-p-black",
  "bg-p-black-lighter",
  "bg-p-black-lightest",
  "bg-warning",
  "bg-warning-lighter",
  "bg-warning-lightest",
  "bg-success",
  "bg-success-lighter",
  "bg-success-lightest",
  "bg-danger",
  "bg-danger-lighter",
  "bg-danger-lightest",
  "sm:mx-auto",
  "sm:w-full",
  "sm:max-w-sm",
  "sm:max-w-md",
  "text-xl",
  "hover:brightness-95",
  "mt-12",
  "gap-6",
  "mb-6",
  "list-disc",
  "list-inside",
  "fixed",
  "w-screen",
  "left-0",
  "bottom-0",
  "px-4",
  "pb-4",
  "bg-p-white",
  "drop-shadow-p-white-up",
  "mb-12",
  "sm:static",
  "sm:p-0",
  "sm:w-full",
  "gap-5",
  "p-2",
];

// We can put any styles we want in this component and they will then be packaged into
// the resulting CSS/JS bundles.
const ExportComponent = () => (
  <div className={classNames(...exportClassNames)}>Hello World</div>
);

export default ExportComponent;
