import React from "react";

type PErrors = {
  errors?: string[];
};

const PErrorsComponent = (props: PErrors) => {
  const { errors } = props;

  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col">
      {errors.map((e) => (
        <div className="text-danger text-left text-xs" key={e}>
          {e}
        </div>
      ))}
    </div>
  );
};

PErrorsComponent.defaultProps = {
  errors: [],
};

export default PErrorsComponent;
