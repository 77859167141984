import {
  ApiError,
  ApiResponse,
  Fetcher,
  Middleware,
} from "openapi-typescript-fetch";
import { components, paths } from "./spec";

export type Wrapper = <Type>(p: Promise<Type>) => Promise<Type>;

let endpoint = "http://localhost:8080";

export const setEndpoint = (newEndpoint: string) => {
  endpoint = newEndpoint;
};

const useFetcher = () => {
  const fetcher = Fetcher.for<paths>();
  fetcher.configure({
    baseUrl: endpoint,
    init: {
      credentials: "include",
    },
  });
  return fetcher;
};

const useMiddlewareFetcher = (middlewares: Middleware[]) => {
  const fetcher = useFetcher();
  middlewares.forEach((middleware) => fetcher.use(middleware));
  return fetcher;
};

export const useWrappersFetcher = (wrappers: Wrapper[]) => {
  const middlewares = wrappers.map(
    (wrapper): Middleware =>
      async (url, init, next) =>
        wrapper(next(url, init)),
  );
  return useMiddlewareFetcher(middlewares);
};

export const fetchAndUnpackError = async <T>(
  apiCall: Promise<ApiResponse<T>>,
): Promise<
  [T | null, components["schemas"]["BadInputResponseWrapper"] | null]
> => {
  try {
    const response = await apiCall;
    return [response.data, null];
  } catch (e) {
    if (e instanceof ApiError && e.status === 400) {
      return [null, e.data];
    }
    throw e;
  }
};
