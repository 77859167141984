import React from "react";
import { components } from "../../../../api/spec";
import PIconEditButton from "../../../ui/buttons/PIconEditButton";

type QuizSummaryRowProps = {
  quiz: components["schemas"]["ReflectionQuizSummary"];
  onEditClicked: () => void;
};

const QuizSummaryRowComponent = (props: QuizSummaryRowProps) => {
  const { quiz, onEditClicked } = props;

  return (
    <tr key={quiz.guid}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {quiz.title}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {quiz.time_created}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {quiz.completed_recipient_count} / {quiz.recipient_count}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {quiz.is_completed ? "Yes" : "No"}
      </td>
      <td
        aria-label="Edit Quiz"
        className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
      >
        <PIconEditButton onClick={onEditClicked} tooltip="Edit Quiz" />
      </td>
    </tr>
  );
};

export default QuizSummaryRowComponent;
