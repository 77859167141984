import React, { useState } from "react";
import { PNavbarScreenProps } from "../../ui/layout/PNavbarScreen";
import ReverseScreen from "./ReverseScreen";
import { useBusyWatcher } from "../../../util/hooks";
import { components } from "../../../api/spec";
import { reverseString } from "../../../api/backend/example";

const ReversePageComponent = (props: PNavbarScreenProps) => {
  const [response, setResponse] = useState<
    components["schemas"]["ReverseResponseSerializerWrapper"] | null
  >(null);
  const [error, setError] = useState<
    components["schemas"]["BadInputResponseWrapper"] | null
  >(null);
  const [_, busyWatcher] = useBusyWatcher();

  const onSubmit = async (input: components["schemas"]["ReverseRequest"]) => {
    setResponse(null);
    setError(null);
    const [newResponse, newError] = await reverseString(input, [busyWatcher]);
    setResponse(newResponse);
    setError(newError);
  };

  return (
    <ReverseScreen
      {...props}
      response={response}
      error={error}
      onSubmit={onSubmit}
    />
  );
};

export default ReversePageComponent;
