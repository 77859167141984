import React, { forwardRef, ReactNode } from "react";
import { classNames } from "../../../util/strings";

export type PButtonKind =
  | "primary"
  | "secondary"
  | "danger"
  | "warning"
  | "success";

export const getKindClass = (kind: PButtonKind, disabled: boolean): string => {
  if (disabled) {
    switch (kind) {
      case "primary":
        return `cursor-not-allowed bg-p-black-lighter text-secondary border-p-black-lighter font-semibold`;
      default:
        return `cursor-not-allowed bg-p-black-lightest border-p-black-lightest font-semibold`;
    }
  }

  const hoverClass = "hover:brightness-110 active:brightness-90";
  switch (kind) {
    case "danger":
      return `${hoverClass} bg-danger text-secondary font-semibold border-danger`;
    case "warning":
      return `${hoverClass} bg-warning text-secondary font-semibold border-warning`;
    case "primary":
      return `${hoverClass} bg-primary text-secondary border-primary font-semibold`;
    case "success":
      return `${hoverClass} bg-success text-secondary font-semibold border-success`;
    case "secondary":
    default:
      return `${hoverClass} bg-secondary text-primary border-primary`;
  }
};

type ButtonMetaProps = {
  kind?: PButtonKind;
  icon?: ReactNode;
  disabled?: boolean;
};

export type ButtonButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonMetaProps & { href?: undefined };
type AnchorButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonMetaProps & { href: string };

export type PButtonProps = ButtonButtonProps | AnchorButtonProps;

const PButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, PButtonProps>(
  (
    {
      children,
      kind,
      disabled,
      className: classNameProp,
      icon: iconProp,
      ...props
    },
    ref,
  ) => {
    const className = classNames(
      "px-2 py-1 inline-flex flex-row items-center justify-center gap-2 border-2",
      getKindClass(kind!, disabled || false),
      classNameProp,
      children ? null : "w-9 h-9",
    );

    const innards = iconProp ? (
      <>
        {iconProp}
        {children ? <span>{children}</span> : null}
      </>
    ) : (
      children
    );

    if (props.href) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <a
          ref={ref as React.Ref<HTMLAnchorElement>}
          className={className}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            }
          }}
          {...props}
        >
          {innards}
        </a>
      );
    }

    return (
      // eslint-disable-next-line react/button-has-type
      <button
        ref={ref as React.Ref<HTMLButtonElement>}
        className={className}
        disabled={disabled}
        {...(props as ButtonButtonProps)}
      >
        {innards}
      </button>
    );
  },
);

PButton.defaultProps = {
  kind: "secondary",
  icon: undefined,
  disabled: undefined,
};

export default PButton;
