import { components, operations } from "../spec";
import { fetchAndUnpackError, useWrappersFetcher, Wrapper } from "../connector";

// eslint-disable-next-line import/prefer-default-export
export const getTaskStatus = async (
  request: operations["api_tasks_status_retrieve"]["parameters"]["query"],
  wrappers: Wrapper[] = [],
): Promise<
  [
    components["schemas"]["GetTaskStatusResponseSerializerWrapper"] | null,
    components["schemas"]["BadInputResponseWrapper"] | null,
  ]
> =>
  fetchAndUnpackError(
    useWrappersFetcher(wrappers)
      .path("/api/tasks/status")
      .method("get")
      .create()(request),
  );
