import React, { ReactNode } from "react";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import PButton, { PButtonProps } from "./PButton";

type PBackButtonProps = PButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const PBackButtonComponent = (props: PBackButtonProps) => {
  const { text, ...rest } = props;

  return <PButton {...rest}>{text}</PButton>;
};

PBackButtonComponent.defaultProps = {
  text: "back",
  icon: <ChevronLeftIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default PBackButtonComponent;
