import React from "react";
import PEditTextScreen from "../../../ui/screens/PEditTextScreen";

type QuizCoreQuestionScreenProps = {
  onNextClicked: (coreQuestion: string) => void;
  errors: string[];
};

const QuizCoreQuestionScreenComponent = (
  props: QuizCoreQuestionScreenProps,
) => {
  const { onNextClicked, errors } = props;

  return (
    <PEditTextScreen
      title="What is the core question of your quiz?"
      comment="Your answer here will determine the questions that we generate for you, so be as specific as possible!"
      onNextClicked={onNextClicked}
      errors={errors}
      label="core question"
      placeholder="ex: I want to learn more about what my team thinks of my management style and where I could improve."
      required
      long
    />
  );
};

export default QuizCoreQuestionScreenComponent;
