import { components } from "../api/spec";

// eslint-disable-next-line import/prefer-default-export
export const errorsForField = (
  errors: components["schemas"]["BadInputResponseWrapper"],
  field: string,
  includeMessage = true,
): string[] => {
  const result = errors.error_fields
    .filter((e) => e.field === field)
    .map((e) => e.message);
  if (result.length > 0) {
    return result;
  }
  if (includeMessage && errors.message) {
    return [errors.message];
  }
  return [];
};
