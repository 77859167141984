import React from "react";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import QuizQuestionCard from "./QuizQuestionCard";
import { components } from "../../../../api/spec";

type QuizQuestionsScreenProps = {
  questions: components["schemas"]["QuizQuestion"][];
  onNextClicked: () => void;
};

const QuizQuestionScreenComponent = (props: QuizQuestionsScreenProps) => {
  const { questions, onNextClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <PFormScreen
      nextDisabled={busy}
      onNextClicked={onNextClicked}
      title="Your Questions"
      comment="These are the questions in your quiz. They are not editable (for now...)!"
    >
      <div className="flex flex-col gap-3">
        {questions.map((question, index) => (
          <QuizQuestionCard
            key={question.description}
            question={question}
            index={index}
          />
        ))}
      </div>
    </PFormScreen>
  );
};

export default QuizQuestionScreenComponent;
