import React from "react";
import { Recipient } from "./RecipientEntryRow";
import PFormScreen from "../../../ui/screens/PFormScreen";
import { useBusyWatcher } from "../../../../util/hooks";
import PCard from "../../../ui/PCard";

type QuizCreationCompletedScreenProps = {
  title: string;
  description: string;
  recipients: Recipient[];
  onNextClicked: () => void;
};

const QuizCreationCompletedScreenComponent = (
  props: QuizCreationCompletedScreenProps,
) => {
  const { title, description, recipients, onNextClicked } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <PFormScreen
      title="Quiz Created"
      comment="Your quiz has been created and sent to the recipients below."
      nextDisabled={busy}
      onNextClicked={onNextClicked}
    >
      <PCard>
        <div className="font-semibold">Title:</div>
        <div>{title}</div>
        <div className="font-semibold mt-3">Description:</div>
        <div>{description}</div>
        <div className="font-semibold mt-3">Recipients:</div>
        <ul className="list-disc ml-4">
          {recipients.map((recipient) => (
            <li key={recipient.emailAddress}>
              <div className="flex flex-row gap-2 items-center">
                <div className="text-sm">
                  {recipient.firstName} {recipient.lastName}
                </div>
                <div className="text-xs italic">{recipient.emailAddress}</div>
              </div>
            </li>
          ))}
        </ul>
      </PCard>
    </PFormScreen>
  );
};

export default QuizCreationCompletedScreenComponent;
