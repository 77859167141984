import React from "react";
import PCard from "../../../ui/PCard";
import { components } from "../../../../api/spec";

type QuizQuestionCardProps = {
  question: components["schemas"]["QuizQuestion"];
  index: number;
};

const QuizQuestionCardComponent = (props: QuizQuestionCardProps) => {
  const { question, index } = props;

  return (
    <PCard>
      <div className="grid grid-cols-12">
        <div className="col-span-1 font-bold self-center">{index + 1}.</div>
        <div className="col-span-11 flex flex-col gap-2">
          <div className="font-bold">{question.title}</div>
          <div>{question.description}</div>
        </div>
      </div>
    </PCard>
  );
};

export default QuizQuestionCardComponent;
