import React, { ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import PButton, { PButtonProps } from "./PButton";

type PClearButtonProps = PButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const PClearButtonComponent = (props: PClearButtonProps) => {
  const { text, ...rest } = props;

  return <PButton {...rest}>{text}</PButton>;
};

PClearButtonComponent.defaultProps = {
  text: "clear",
  icon: <XMarkIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default PClearButtonComponent;
