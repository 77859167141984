import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useReducer,
} from "react";

// https://stackoverflow.com/questions/59432133/how-to-type-state-and-dispatch-for-usereducer-typescript-and-react

export enum StateUpdate {
  INCR_BUSY_COUNTER,
  DECR_BUSY_COUNTER,
}

export type GlobalState = {
  busy: boolean;
  busyCounter: number;
};

export const initialState: GlobalState = {
  busy: false,
  busyCounter: 0,
};

export const GlobalContext = createContext<
  [GlobalState, Dispatch<StateUpdate>]
  // eslint-disable-next-line @typescript-eslint/no-empty-function
>([initialState, () => {}]);

export const reducer = (
  state: GlobalState,
  action: StateUpdate,
): GlobalState => {
  switch (action) {
    case StateUpdate.INCR_BUSY_COUNTER:
      return {
        ...state,
        busyCounter: state.busyCounter + 1,
        busy: state.busyCounter + 1 > 0,
      };
    case StateUpdate.DECR_BUSY_COUNTER:
      return {
        ...state,
        busyCounter: state.busyCounter - 1,
        busy: state.busyCounter - 1 > 0,
      };
    default:
      return state;
  }
};

export const GlobalContextProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  );
};
