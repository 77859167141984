import React, { ReactNode } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import PButton, { PButtonProps } from "./PButton";

type PPlusButtonProps = PButtonProps & {
  text?: string | null;
  icon?: ReactNode;
};

const MPlusButtonComponent = (props: PPlusButtonProps) => {
  const { text, ...rest } = props;

  return <PButton {...rest}>{text}</PButton>;
};

MPlusButtonComponent.defaultProps = {
  text: "add",
  icon: <PlusIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default MPlusButtonComponent;
