import { Wrapper } from "../api/connector";
import { getTaskStatus } from "../api/backend/task";

// eslint-disable-next-line import/prefer-default-export
export const waitForTask = async (
  task: string,
  wrappers: Wrapper[] = [],
  timeout = 60_000,
  checkInterval = 500,
): Promise<boolean> => {
  const maxLoops = timeout / checkInterval;
  for (let i = 0; i < maxLoops; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    const [success, _] = await getTaskStatus({ task }, wrappers);
    if (success && success.content.ready) {
      return true;
    }
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(resolve, checkInterval);
    });
  }
  return false;
};
