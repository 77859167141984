import React from "react";
import { toast } from "react-toastify";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../ui/layout/PNavbarScreen";
import PTitle from "../../ui/PTitle";
import PButton from "../../ui/buttons/PButton";

const ToastScreenComponent = (props: PNavbarScreenProps) => {
  const { ...rest } = props;

  return (
    <PNavbarScreen {...rest}>
      <div className="flex flex-col gap-3 items-start">
        <PTitle title="Have a toast" />
        <PButton
          kind="primary"
          onClick={() => toast.info("This is an informational toast.")}
        >
          info
        </PButton>
        <PButton
          kind="success"
          onClick={() => toast.success("This is a success toast.")}
        >
          success
        </PButton>
        <PButton
          kind="warning"
          onClick={() => toast.warning("This is a warning toast.")}
        >
          warning
        </PButton>
        <PButton
          kind="danger"
          onClick={() => toast.error("This is an error toast.")}
        >
          error
        </PButton>
      </div>
    </PNavbarScreen>
  );
};

export default ToastScreenComponent;
