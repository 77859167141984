import React, { PropsWithChildren } from "react";
import { classNames } from "../../../util/strings";

type PMiddleMarginsLayoutProps = {
  verticalPadding?: boolean;
};

const PMiddleMarginsLayoutComponent = (
  props: PropsWithChildren<PMiddleMarginsLayoutProps>,
) => {
  const { verticalPadding, children } = props;

  return (
    <div
      className={classNames(
        "mx-auto max-w-7xl px-4 sm:px-6 lg:px-8",
        verticalPadding ? "py-4" : null,
      )}
    >
      {children}
    </div>
  );
};

PMiddleMarginsLayoutComponent.defaultProps = {
  verticalPadding: true,
};

export default PMiddleMarginsLayoutComponent;
