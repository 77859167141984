import React, { ReactNode } from "react";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import PButton, { PButtonProps } from "./PButton";

type PNextButtonProps = PButtonProps & {
  text?: string;
  icon?: ReactNode;
};

const PNextButtonComponent = (props: PNextButtonProps) => {
  const { text, ...rest } = props;

  return <PButton {...rest}>{text}</PButton>;
};

PNextButtonComponent.defaultProps = {
  text: "next",
  icon: <ChevronRightIcon className="h-6 w-5 flex-none" aria-hidden="true" />,
};

export default PNextButtonComponent;
