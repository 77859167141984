import React, { useEffect, useState } from "react";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../../ui/layout/PNavbarScreen";
import { components } from "../../../../api/spec";
import { useBusyWatcher } from "../../../../util/hooks";
import ListQuizzesScreen from "./ListQuizzesScreen";
import { listQuizzes } from "../../../../api/reflection/quizzes";

type ListQuizzesPageProps = PNavbarScreenProps & {
  createQuizUrl: string;
  editQuizUrl: string;
};

const ListQuizzesPageComponent = (props: ListQuizzesPageProps) => {
  const { createQuizUrl, editQuizUrl, navbar } = props;

  const [_, busyWatcher] = useBusyWatcher();
  const [errors, setErrors] = useState<string[]>([]);
  const [quizzes, setQuizzes] = useState<
    components["schemas"]["ListQuizzesResponseSerializerWrapper"] | null
  >(null);

  const loadQuizzes = async () => {
    setErrors([]);
    const [newQuizzes, newErrors] = await listQuizzes({}, [busyWatcher]);
    if (newErrors !== null) {
      setErrors([newErrors!.message!]);
      return;
    }
    setQuizzes(newQuizzes!);
  };

  const onCreateQuizClicked = () => {
    window.location.href = createQuizUrl;
  };

  const onEditQuizClicked = (quizGuid: string) => {
    window.location.href = `${editQuizUrl}?quiz=${quizGuid}`;
  };

  useEffect(() => {
    loadQuizzes();
  }, []);

  return (
    <PNavbarScreen navbar={navbar}>
      <ListQuizzesScreen
        onCreateNewClicked={onCreateQuizClicked}
        onEditQuizClicked={onEditQuizClicked}
        quizzes={quizzes}
        errors={errors}
      />
    </PNavbarScreen>
  );
};

export default ListQuizzesPageComponent;
