import React from "react";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../ui/layout/PNavbarScreen";

const LandingScreenComponent = (props: PNavbarScreenProps) => {
  const { navbar } = props;

  return (
    <PNavbarScreen navbar={navbar}>
      <h1>Landing Page</h1>
    </PNavbarScreen>
  );
};

export default LandingScreenComponent;
