import React from "react";
import PEditTextScreen from "../../../ui/screens/PEditTextScreen";

type QuizAudienceScreenProps = {
  onNextClicked: (audience: string) => void;
  errors: string[];
};

const QuizAudienceScreenComponent = (props: QuizAudienceScreenProps) => {
  const { onNextClicked, errors } = props;

  return (
    <PEditTextScreen
      title="Who is the intended audience of your quiz?"
      comment="By sharing information about who you intend to send this quiz to we'll be able to better design our questions and cater them to your audience."
      onNextClicked={onNextClicked}
      errors={errors}
      label="audience"
      placeholder="ex: I'll be sharing this with all of my direct reports as well as my immediate peers."
      required
      long
    />
  );
};

export default QuizAudienceScreenComponent;
