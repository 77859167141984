import React from "react";
import "./index.scss";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import posthog, { PostHog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import reportWebVitals from "./reportWebVitals";
import { setEndpoint } from "./api/connector";
import { GlobalContextProvider } from "./util/context";
import { PubSubProvider } from "./util/pubsub";
import ReversePage from "./components/backend/reverse/ReversePage";
import TaskPage from "./components/backend/task/TaskPage";
import ToastScreen from "./components/backend/toast/ToastScreen";
import PNav from "./components/ui/PNav";
import Export from "./components/backend/export/Export";
import LandingScreen from "./components/backend/landing/LandingScreen";
import ListQuizzesPage from "./components/reflection/quizzes/list/ListQuizzesPage";
import CreateQuizPage from "./components/reflection/quizzes/create/CreateQuizPage";

type ReactDataInput = {
  api_base_path: string | null;
  posthog_enabled: boolean | null;
  posthog_api_key: string | null;
  posthog_host: string | null;
};

type UserInfo = {
  guid: string;
  username: string;
  name: string;
  email: string;
};

const getUserInfo = (): UserInfo | null => {
  const userInfoElement = document.getElementById("user-info");
  if (!userInfoElement) {
    return null;
  }
  return JSON.parse(userInfoElement.textContent!);
};

const setupAnalytics = (data: ReactDataInput) => {
  if (data.posthog_enabled) {
    // eslint-disable-next-line no-console
    console.log("Setting up Posthog...");
    posthog.init(data.posthog_api_key!, {
      api_host: data.posthog_host!,
      loaded: (ph: PostHog) => {
        const userInfo = getUserInfo();
        if (!userInfo) {
          return;
        }
        ph.identify(userInfo.guid);
      },
    });
  }
};

// Elements referenced in elementMap are ones that are effectively "exported" from the
// JS bundle and able to be mounted via Django into HTML pages.

const elementMap: any = {
  ReversePage,
  TaskPage,
  ToastScreen,
  PNav,
  Export,
  LandingScreen,
  CreateQuizPage,
  ListQuizzesPage,
};

const renderReactComponent = (
  container: HTMLElement,
  config: ReactDataInput | null,
) => {
  // The first child of the container is the element we want to render into

  const placement = container.children[0];

  // The second child of the container is the props for the component

  let props: any = {};
  if (container.children[1].textContent !== "") {
    props = JSON.parse(container.children[1].textContent!);
  }

  // Render the component

  const componentType = (placement as HTMLDivElement).dataset.component;
  const root = createRoot(placement);
  const element = React.createElement(elementMap[componentType!], props, null);
  let toWrap = <PubSubProvider>{element}</PubSubProvider>;
  if (config?.posthog_enabled === true) {
    toWrap = <PostHogProvider client={posthog}>{toWrap}</PostHogProvider>;
  }
  root.render(<GlobalContextProvider>{toWrap}</GlobalContextProvider>);
};

document.addEventListener("DOMContentLoaded", () => {
  // Pull any React data input that is found on the page

  let reactData: ReactDataInput | null = null;
  const reactDataContainer = document.getElementById("react_input_data");
  if (reactDataContainer) {
    reactData = JSON.parse(reactDataContainer.innerText);
  }

  // Update global state as needed

  if (reactData) {
    // eslint-disable-next-line no-console
    console.log("Setting API base path to ", reactData.api_base_path);
    setEndpoint(reactData.api_base_path!);
    setupAnalytics(reactData);
  }

  // Render toaster on the page as needed

  const element = document.getElementById("toaster");
  if (element !== null) {
    const root = createRoot(element);
    root.render(
      <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>,
    );
  }

  // Render any React components on the page

  const reactElements = document.querySelectorAll(".react-component-container");
  for (let i = 0; i < reactElements.length; i += 1) {
    renderReactComponent(reactElements[i] as HTMLElement, reactData);
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
