import React from "react";
import { classNames } from "../../../util/strings";

type PCharacterCountProps = {
  maxLength: number;
  curLength: number;
};

const PCharacterCountComponent = (props: PCharacterCountProps) => {
  const { maxLength, curLength } = props;

  return (
    <div
      className={classNames(
        "text-xs text-right",
        curLength > maxLength ? "text-danger" : null,
      )}
    >
      {curLength}/{maxLength} characters
    </div>
  );
};

export default PCharacterCountComponent;
