import React, { PropsWithChildren, ReactElement } from "react";
import PNextButton from "../buttons/PNextButton";
import PSkipButton from "../buttons/PSkipButton";
import PClearButton from "../buttons/PClearButton";
import PBackButton from "../buttons/PBackButton";
import PCenterColumnStickyButtonScreen from "./PCenterColumnStickyButtonScreen";
import PTitle from "../PTitle";
import PSubtext from "../PSubtext";

type PFormScreenProps = {
  title?: string | null;
  comment?: string | null;
  nextText?: string;
  nextDisabled?: boolean;
  onNextClicked?: (() => void) | null;
  backDisabled?: boolean;
  onBackClicked?: (() => void) | null;
  clearDisabled?: boolean;
  onClearClicked?: (() => void) | null;
  skipDisabled?: boolean;
  onSkipClicked?: (() => void) | null;
  fullWidth?: boolean;
  stacked?: boolean;
};

const PFormScreenComponent = (props: PropsWithChildren<PFormScreenProps>) => {
  const {
    title,
    comment,
    nextText,
    nextDisabled,
    onNextClicked,
    backDisabled,
    onBackClicked,
    clearDisabled,
    onClearClicked,
    skipDisabled,
    onSkipClicked,
    children,
    fullWidth,
    stacked,
  } = props;

  const getButtons = (): ReactElement[] => {
    const toReturn: ReactElement[] = [];

    if (onNextClicked) {
      toReturn.push(
        <PNextButton
          key="next-button"
          disabled={nextDisabled}
          onClick={onNextClicked}
          kind="primary"
          className="w-full"
          text={nextText}
        />,
      );
    }
    if (onSkipClicked) {
      toReturn.push(
        <PSkipButton
          key="skip-button"
          disabled={skipDisabled}
          onClick={onSkipClicked}
          className="w-full"
          kind="secondary"
        />,
      );
    }
    if (onClearClicked) {
      toReturn.push(
        <PClearButton
          key="clear-button"
          disabled={clearDisabled}
          onClick={onClearClicked}
          className="w-full"
          kind="secondary"
        />,
      );
    }
    if (onBackClicked) {
      toReturn.push(
        <PBackButton
          key="back-button"
          disabled={backDisabled}
          onClick={onBackClicked}
          className="w-full"
          kind="secondary"
        />,
      );
    }
    return toReturn;
  };

  return (
    <PCenterColumnStickyButtonScreen
      fullWidth={fullWidth}
      buttons={getButtons()}
      stacked={stacked}
    >
      {title && <PTitle title={title} className="text-center" />}
      <PSubtext text={comment} className="text-center" />
      <div className="flex flex-col gap-3">{children}</div>
    </PCenterColumnStickyButtonScreen>
  );
};

PFormScreenComponent.defaultProps = {
  onNextClicked: null,
  nextDisabled: undefined,
  onBackClicked: null,
  backDisabled: undefined,
  onClearClicked: null,
  clearDisabled: undefined,
  onSkipClicked: null,
  skipDisabled: undefined,
  comment: null,
  fullWidth: true,
  nextText: "next",
  stacked: false,
  title: null,
};

export default PFormScreenComponent;
