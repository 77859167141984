import {
  RefObject,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { getUniqueId } from "./random";
import { GlobalContext, StateUpdate } from "./context";

export const useUniqueId = (): string => useMemo(() => getUniqueId(), []);

export const useBusyWatcher = (): [
  boolean,
  <Type>(p: Promise<Type>) => Promise<Type>,
] => {
  // One thing that's tricky here is that requests are often made back to back, which will
  // result in the busy state being set to true and then immediately set to false. To avoid
  // this, we immediately set busy state to true when we observe an outbound request, but we
  // debounce the busy state to false so that it only goes back to false after a short delay.

  const busyRef = useRef<boolean>(false);
  const [state, dispatch] = useContext(GlobalContext);
  const [isBusy, setIsBusy] = useState<boolean>(busyRef.current);

  useEffect(() => {
    busyRef.current = state.busy;
    if (state.busy && !isBusy) {
      setIsBusy(true);
    }
    if (!state.busy && isBusy) {
      setTimeout(() => {
        if (!busyRef.current) {
          setIsBusy(false);
        }
      }, 100);
    }
  }, [state.busy]);

  const promiseWrapper = async <Type>(
    promise: Promise<Type>,
  ): Promise<Type> => {
    dispatch(StateUpdate.INCR_BUSY_COUNTER);
    try {
      return await promise;
    } finally {
      dispatch(StateUpdate.DECR_BUSY_COUNTER);
    }
  };

  return [isBusy, promiseWrapper];
};

export const useSize = <T extends HTMLElement>(
  target: RefObject<T>,
): DOMRect | null => {
  const [size, setSize] = useState<DOMRect | null>(null);

  useLayoutEffect(() => {
    if (target && target.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};
