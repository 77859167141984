import React, { PropsWithChildren, useRef } from "react";
import PNav, { PNavProps } from "../PNav";
import { useSize } from "../../../util/hooks";
import PMiddleMarginsLayout from "./PMiddleMarginsLayout";

export type PNavbarScreenProps = {
  navbar: PNavProps;
};

const PNavbarScreenComponent = (
  props: PropsWithChildren<PNavbarScreenProps>,
) => {
  const { navbar, children } = props;

  const navRef = useRef<HTMLDivElement>(null);
  const navSize = useSize(navRef);

  const getTopMarginForBody = (): number => {
    if (navSize) {
      return navSize.height;
    }
    return 76;
  };

  return (
    <>
      <div className="w-screen fixed top-0 left-0 z-10" ref={navRef}>
        <PNav {...navbar} />
      </div>
      <div
        className="flex-1 w-full mx-auto"
        style={{
          marginTop: `${getTopMarginForBody()}px`,
        }}
      >
        <div className="min-h-full h-full">
          <PMiddleMarginsLayout>{children}</PMiddleMarginsLayout>
        </div>
      </div>
    </>
  );
};

export default PNavbarScreenComponent;
