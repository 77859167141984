import React, { useState } from "react";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../ui/layout/PNavbarScreen";
import PTitle from "../../ui/PTitle";
import PInput from "../../ui/input/PInput";
import PButton from "../../ui/buttons/PButton";
import { useBusyWatcher } from "../../../util/hooks";
import { errorsForField } from "../../../util/errors";
import { components } from "../../../api/spec";

type ReverseScreenProps = PNavbarScreenProps & {
  response: components["schemas"]["ReverseResponseSerializerWrapper"] | null;
  error: components["schemas"]["BadInputResponseWrapper"] | null;
  onSubmit: (input: components["schemas"]["ReverseRequest"]) => void;
};

const ReverseScreenComponent = (props: ReverseScreenProps) => {
  const { response, error, onSubmit, ...rest } = props;

  const [input, setInput] = useState<string>("");
  const [busy, _] = useBusyWatcher();

  const canSubmit = (): boolean => {
    if (input === "") {
      return false;
    }
    return true;
  };

  return (
    <PNavbarScreen {...rest}>
      <div className="flex flex-col gap-3 items-start">
        <PTitle title="Reverse a string" />
        <PInput
          type="text"
          maxLength={100}
          onChange={(e) => setInput(e.target.value)}
          label="input to reverse"
          errors={error ? errorsForField(error, "input") : []}
          onEnterPressed={() => canSubmit() && onSubmit({ input })}
        />
        <PButton
          disabled={!canSubmit() || busy}
          kind="primary"
          onClick={() => onSubmit({ input })}
        >
          reverse it
        </PButton>
        <PTitle title="Results..." />
        {response !== null ? <div>{response.content.reversed}</div> : null}
        {response === null ? (
          <div className="italic">no results yet</div>
        ) : null}
      </div>
    </PNavbarScreen>
  );
};

export default ReverseScreenComponent;
