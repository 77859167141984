import React from "react";
import { classNames } from "../../util/strings";

export type PTitleProps = {
  title: string;
  className?: string | null;
};

const PTitleComponent = (props: PTitleProps) => {
  const { title, className } = props;

  return (
    <h1 className={classNames("text-lg font-semibold leading-6", className)}>
      {title}
    </h1>
  );
};

PTitleComponent.defaultProps = {
  className: null,
};

export default PTitleComponent;
