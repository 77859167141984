import React from "react";
import { components } from "../../../api/spec";
import PNavbarScreen, {
  PNavbarScreenProps,
} from "../../ui/layout/PNavbarScreen";
import PTitle from "../../ui/PTitle";
import PButton from "../../ui/buttons/PButton";
import { useBusyWatcher } from "../../../util/hooks";

type TaskScreenProps = PNavbarScreenProps & {
  onGenerateClicked: () => void;
  isLoading: boolean;
  task:
    | components["schemas"]["ExampleRandomTaskDetailSerializerWrapper"]
    | null;
};

const TaskScreenComponent = (props: TaskScreenProps) => {
  const { onGenerateClicked, isLoading, task, ...rest } = props;

  const [busy, _] = useBusyWatcher();

  return (
    <PNavbarScreen {...rest}>
      <div className="flex flex-col gap-3 items-start">
        <PTitle title="Generate a random phrase" />
        <PButton
          disabled={busy || isLoading}
          kind="primary"
          onClick={onGenerateClicked}
        >
          generate
        </PButton>
        <PTitle title="Results..." />
        {isLoading ? <div>Generating a random phrase...</div> : null}
        {!isLoading && task !== null ? <div>{task.content.phrase}</div> : null}
        {!isLoading && task === null ? (
          <div className="italic">no results yet</div>
        ) : null}
      </div>
    </PNavbarScreen>
  );
};

export default TaskScreenComponent;
