import React, { ReactElement } from "react";
import { useUniqueId } from "../../../util/hooks";
import { classNames } from "../../../util/strings";
import PTooltip from "../PTooltip";

export type Kind = "regular" | "small";

type PWellButtonProps = {
  onClick?: (() => void) | null;
  href?: string | null;
  disabled?: boolean;
  text?: string | null;
  icon?: ReactElement | null;
  tooltip?: string | null;
  className?: string | null;
  kind?: Kind;
};

const PWellButtonComponent = (props: PWellButtonProps) => {
  const { onClick, href, disabled, className, text, icon, tooltip, kind } =
    props;
  const uniqueId = useUniqueId();

  const overrideText: boolean = className?.includes("text-") ?? false;

  const content = (
    <div
      className={classNames(
        "flex flex-row items-center hover:underline decoration-primary",
        overrideText ? null : "text-primary",
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        className,
      )}
    >
      {text && <span className="mr-1">{text}</span>}
      {icon && (
        <div
          className={classNames(
            "hover:brightness-110",
            kind === "regular" ? "h-6 w-6" : "h-4 w-4",
          )}
        >
          {icon}
        </div>
      )}
    </div>
  );

  if (onClick) {
    return (
      <>
        <button
          type="button"
          disabled={disabled}
          onClick={onClick}
          data-tooltip-id={uniqueId}
          data-tooltip-content={tooltip ?? ""}
        >
          {content}
        </button>
        <PTooltip id={uniqueId} />
      </>
    );
  }

  if (href) {
    return (
      <>
        <a
          href={href}
          data-tooltip-id={uniqueId}
          data-tooltip-content={tooltip ?? ""}
          onClick={(e) => {
            if (disabled) {
              e.preventDefault();
            }
          }}
        >
          {content}
        </a>
        <PTooltip id={uniqueId} />
      </>
    );
  }

  throw new Error("MWellButtonComponent must have either onClick or href");
};

PWellButtonComponent.defaultProps = {
  disabled: false,
  icon: null,
  text: null,
  tooltip: null,
  className: null,
  kind: "regular",
  onClick: null,
  href: null,
};

export default PWellButtonComponent;
