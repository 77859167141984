import React, { useState } from "react";
import { toast } from "react-toastify";
import { PNavbarScreenProps } from "../../ui/layout/PNavbarScreen";
import { components } from "../../../api/spec";
import { useBusyWatcher } from "../../../util/hooks";
import {
  generateRandomPhrase,
  getExamplePhrase,
} from "../../../api/backend/example";
import { waitForTask } from "../../../util/tasks";
import TaskScreen from "./TaskScreen";

const TaskPageComponent = (props: PNavbarScreenProps) => {
  const { navbar } = props;

  const [response, setResponse] = useState<
    components["schemas"]["ExampleRandomTaskDetailSerializerWrapper"] | null
  >(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [_, busyWatcher] = useBusyWatcher();

  const onGenerateClicked = async () => {
    setResponse(null);
    setIsLoading(true);

    const errorOut = (phrase: string) => {
      toast.error(phrase);
      setIsLoading(false);
    };

    // Kick off the generation and then wait for the associated task to complete

    const [generateResponse, __] = await generateRandomPhrase(
      { include_delay: true },
      [busyWatcher],
    );
    if (generateResponse === null) {
      return errorOut("failed to generate a random phrase");
    }
    const success = await waitForTask(generateResponse.content.task_id!, [
      busyWatcher,
    ]);
    if (!success) {
      return errorOut("failed to wait for the task to complete");
    }
    const [taskResponse, ___] = await getExamplePhrase(
      { phrase: generateResponse.content.guid },
      [busyWatcher],
    );
    if (taskResponse === null) {
      return errorOut("failed to get the task response");
    }

    // Everything looks good, update the UI

    setResponse(taskResponse);
    setIsLoading(false);
    return null;
  };

  return (
    <TaskScreen
      navbar={{ forceLoading: isLoading, ...navbar }}
      onGenerateClicked={onGenerateClicked}
      isLoading={isLoading}
      task={response}
    />
  );
};

export default TaskPageComponent;
