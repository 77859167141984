import React, { ReactNode } from "react";
import PPlusButton from "../../../ui/buttons/PPlusButton";
import { useBusyWatcher } from "../../../../util/hooks";
import { components } from "../../../../api/spec";
import QuizSummaryRow from "./QuizSummaryRow";
import PErrors from "../../../ui/input/PErrors";

type ListQuizzesScreenProps = {
  onCreateNewClicked: () => void;
  onEditQuizClicked: (quizGuid: string) => void;
  quizzes: components["schemas"]["ListQuizzesResponseSerializerWrapper"] | null;
  errors: string[];
};

const ListQuizzesScreenComponent = (props: ListQuizzesScreenProps) => {
  const { onCreateNewClicked, onEditQuizClicked, quizzes, errors } = props;

  const [busy, _] = useBusyWatcher();

  const getBody = (): ReactNode => {
    if (errors.length > 0) {
      return <PErrors errors={errors} />;
    }
    if (
      quizzes === null ||
      !quizzes.success ||
      quizzes.content.quizzes.length === 0
    ) {
      return (
        <div>
          You haven't created any quizzes yet. Once you do they will be listed
          here.
        </div>
      );
    }
    return (
      <table className="min-w-full divide-y divide-p-black-lighter">
        <thead className="bg-p-black-lightest">
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Quiz
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Created At
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              # Responses
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Completed?
            </th>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {quizzes.content.quizzes.map((quiz) => (
            <QuizSummaryRow
              quiz={quiz}
              onEditClicked={() => onEditQuizClicked(quiz.guid)}
            />
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Your Quizzes
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the quizzes that you have created alongside their
            status
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <PPlusButton
            onClick={onCreateNewClicked}
            kind="primary"
            disabled={busy}
            text="new quiz"
          />
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            {getBody()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListQuizzesScreenComponent;
