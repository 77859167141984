import { ITooltip, Tooltip } from "react-tooltip";
import React from "react";

const PTooltipComponent = (props: ITooltip) => {
  const innerProps = { ...props };
  innerProps.className =
    "!bg-p-white !text-primary p-2 shadow z-30 max-w-sm whitespace-pre-line";

  return <Tooltip {...innerProps} />;
};

export default PTooltipComponent;
